












































































































































import { FormValidations } from "@/mixins/form-validations";
import { Navigation } from "@/mixins/navigation";
import { Permissions } from "@/mixins/permissions";
import { CustomDate } from "@/mixins/custom-date";
import PageTitle from "@/components/General/PageTitle.vue";
import Component, { mixins } from "vue-class-component";
import Loader from "@/components/General/Loader.vue";
import Empty from "@/components/General/Empty.vue";
import { VueEditor } from "vue2-editor";
import { Notification } from "@/models/notification.interface";
import { InformationPage } from "@/models/information-page.interface";
import { EditorImages } from "@/mixins/editor-images";
import { StyleCheck } from "@/mixins/style-check";

@Component({
  components: { PageTitle, Loader, Empty, VueEditor },
})
export default class Information extends mixins(
  FormValidations,
  Navigation,
  Permissions,
  CustomDate,
  EditorImages,
  StyleCheck
) {
  alertDialog = false;
  loading = false;
  loader = false;
  tab = 0;
  currentTab = 0;
  objectiveTab = 0;
  tabItem = 0;
  informationPages = [
    {
      id: this.$constants.PAGE.PRIVACY_POLICY,
      name: "FooterLinks.privacyPolicy",
      value: "",
    },
    {
      id: this.$constants.PAGE.SALES_AND_PAYMENT_POLICIES,
      name: "FooterLinks.salesAndPaymentPolicy",
      value: "",
    },
    {
      id: this.$constants.PAGE.TERMS_AND_CONDITIONS,
      name: "FooterLinks.termsAndConditions",
      value: "",
    },
    {
      id: this.$constants.PAGE.GENERAL_INFORMATION,
      name: "FooterLinks.generalInformation",
      value: "",
    },
    {
      id: this.$constants.PAGE.MESSAGE_OF_THE_DAY,
      name: "MessageOfTheDay",
      value: "",
    },
  ];

  private keepInPage() {
    this.alertDialog = false;
  }

  private async changePage(tab: number) {
    this.objectiveTab = tab;
    const originalPage =
      this.$store.getters["settings/getCurrentInformationPage"];
    if (
      originalPage.value !== this.getPageValue(this.tabItem) &&
      !this.loader &&
      this.getPageName() !== originalPage.name
    ) {
      this.alertDialog = true;
      this.$nextTick(() => {
        this.tab = this.tabItem;
      });
    } else {
      this.tabItem = this.tab;
      await this.getCurrentPage();
    }
  }

  private async changePageFromDialog() {
    this.alertDialog = false;
    this.tab = this.objectiveTab;
    this.tabItem = this.tab;
    await this.getCurrentPage();
  }

  private async created() {
    await this.getCurrentPage();
  }

  private getPageName(): any {
    for (let index = 0; index < this.informationPages.length; index++) {
      if (index == this.tab) {
        return this.informationPages[index].id;
      }
    }
  }

  private getPageValue(tab: number): any {
    for (let index = 0; index < this.informationPages.length; index++) {
      if (index == tab) {
        return this.informationPages[index].value;
      }
    }
  }

  private async getCurrentPage(): Promise<void> {
    this.loader = true;
    await this.$store
      .dispatch("settings/getCurrentInformationPage", this.getPageName())
      .then(() => {
        const currentPage =
          this.$store.getters["settings/getCurrentInformationPage"];
        this.informationPages.forEach((page) => {
          if (currentPage.name == page.id) {
            page.value = currentPage.value;
          }
        });
      })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Information.fetchError.page"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      })
      .finally(() => {
        this.loader = false;
      });
  }

  private async updateInformationPage(page: InformationPage) {
    this.loading = true;
    if (page.value === null || page.value === undefined) {
      page.value = "";
    }

    await this.$store
      .dispatch("settings/updateCurrentInformationPage", page)
      .then(async () => {
        await this.getCurrentPage();
        const Success: Notification = {
          message: this.$tc("Information.success"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: false,
        };

        this.$store.dispatch("notifications/showNotification", Success);
      })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Information.fetchError.updatePage"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
